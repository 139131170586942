<script>
import Multiselect from "vue-multiselect";
export default {
    props: {
        empresa: { type: Object, required: true },
        ibge: { type: Array },
        produtos2: {type: Array}
    },
    components: { Multiselect },
    data() {
        return {
            titleBody: 'Produto Confecção',
            estados: [],
            estados2: [],
            list_produtos:[],
            items: [
                "produto-1",
                "produto-2",
                "produto-3",
            ],
            product_confec :{
                number_order:2,
                filial_id:1,
                cod:2,
                reference:'ola',
                name: 'produto',
                in_stock:'2',
                amount:'45',
                forecast:'2023-06-30T08:43', // previsão
                processed:1,
                stock_destiny: 1,
                value: 50.00,
                date_destiny: '2023-06-30T08:43',
                alter_last:'alterado',
                user_cod:2,
           },
           name: null,
        }
        
    },
    created() { },
    mounted() {
        this.$emit('newTitle', this.titleBody);
        //console.log(this.ibge);
        //this.convert_citys();
        this.list_produtos = this.produtos2;
    },

    methods: {
        selecionando_name(objeto){
            this.product_confec.name = objeto.produto_nome
            console.log(this.product_confec.name)
        },
        setPost() {
            //console.log(this.cidades);
            this.$emit('doPost', this.product_confec);
        },
    },
}
</script>

<template>
    <div class="card">
        <div class="form-row form-group" role="group">

            <div class="col-sm-12 col-md-6">
                <div class="form-group">

                    <div class="form-group">
                        <label for="tipo_id" class="col-form-label">Numero de ordem</label>
                        <div class="col">
                            <input id="id_num_order" v-model="product_confec.number_order" type="text" class="form-control text-left">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group">
                    <label for="tipo_id" class="col-form-label">Filial ID</label>
                    <div class="col" id="fornecedor_id">
                        <input id="id_filial" v-model="product_confec.filial_id"  type="text" class="form-control text-left" placeholder="codigo">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row form-group" role="group">

            <div class="col-sm-12 col-md-12">
                <div class="form-group">
                    <label for="tipo_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                        <div>
                            <label for="data">Produto:</label>
                            <div id="borda">
                                <div>
                                    <div class="form-row form-group" role="group">
                                        <div class="col-sm-12 col-md-2">
                                            <div class="form-group">
                                                <div class="col">
                                                    <div>
                                                        <label for="data">Codigo</label>
                                                        <input id="id_cod" v-model="product_confec.cod" type="text" class="form-control text-left">
                                                        <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-2">
                                            <div class="form-group">
                                                <div class="col">
                                                    <div>
                                                        <label for="data">Referência</label>
                                                        <input id="id_ref" v-model="product_confec.reference" type="text"
                                                            class="form-control text-left">
                                                        <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-2">
                                            <div class="form-group">
                                                <div class="col">
                                                    <div>
                                                        <label for="data">Nome do Produto</label>
                                                        <multiselect id="name_id_prod" v-model="name" :options="list_produtos"
                                                        label="produto_nome" @select="selecionando_name($event)"></multiselect>
                                                        <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-2">
                                            <div class="form-group">
                                                <div class="col">
                                                    <div>
                                                        <label for="data">Estoque</label>
                                                        <input id="id_stock_in" v-model="product_confec.in_stock" type="text" class="form-control text-left">
                                                        <!-- <button class="btn btn-light" id="btn1" @click="oculta"><i class="fas fa-edit"></i> add item</button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-2">
                                            <div class="form-group">
                                                <div class="col">
                                                    <label for="data">&nbsp;</label>
                                                    <div>
                                                        <label for="data">&nbsp;</label>
                                                        <button class="btn btn-light" >Produtos</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row form-group" role="group">

                <div class="col-sm-12 col-md-2">
                    <div class="form-group">
                        <label for="tipo_id" class="col-form-label">&nbsp;</label>
                        <div class="col">
                            <div>
                                <label for="data">Quantidade :</label>
                                <input id="id_amount"  v-model="product_confec.amount" type="number" class="form-control text-left" placeholder="digite a quantidade">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-3">
                    <div class="form-group">
                        <label for="tipo_id" class="col-form-label">&nbsp;</label>
                        <div class="col">
                            <div>
                                <label for="data">Previsão de Conclusão:</label>
                                <b-form-datepicker id="id_data_prev" v-model="product_confec.forecast"></b-form-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-2">
                    <div class="form-group">
                        <label for="tipo_id" class="col-form-label">&nbsp;</label>
                        <div class="col">
                            <div>
                                <label for="data">Situação:</label>
                                <div class="form-check">
                                    <!-- checked="checked" -->
                                    <input v-model="product_confec.processed" type="radio" name="classe" id="id_situation" value="P" 
                                        class="form-check-input">
                                    <label for="classe1" class="form-check-label">Cosiderar
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3">
                    <div class="form-group">
                        <label for="tipo_id" class="col-form-label">&nbsp;</label>
                        <div class="col">
                            <div>
                                <label for="data">Local de estoque destino :</label>
                                <multiselect id="id_stock_destiny" v-model="product_confec.stock_destiny" :options="items"></multiselect>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-2">
                    <div class="form-group">
                        <label for="tipo_id" class="col-form-label">&nbsp;</label>
                        <div class="col">
                            <div>
                                <label for="data">Valor:</label>
                                <input id="id_value" v-model="product_confec.value" type="text" class="form-control text-left" placeholder="digite nota">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-4">
                <div class="form-group">
                    <label for="tipo_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                        <div>
                            <label for="data">Data de Cadastro :</label>
                            <b-form-datepicker id="data_cad" v-model="product_confec.date_destiny"></b-form-datepicker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group">
                    <label for="tipo_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                        <div>
                            <label for="data">Ultima alteração:</label>
                             <input id="id_alter" v-model="product_confec.alter_last" type="text" class="form-control text-left" placeholder="digite">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="form-group">
                    <label for="tipo_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                        <div>
                            <label for="data">Codigo do Usuario:</label>
                            <input id="user_id" v-model="product_confec.user_cod" type="text" class="form-control text-left" placeholder="digite">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="col-md-12 mt-10">
                <button class="btn btn-light" @click="setPost">Gravar</button>
            </div>
        </div>

        <!-- <div class="card-body">
                      <i
                        v-if="loader.get"
                        class="fa fa-spinner fa-5x fa-spin text-success text-center"
                      ></i>
                      <form id="form-tipo-movimento" role="form" class="form-horizontal">
                        <b-card no-body class="mb-1">
                          <b-card-body>
                            <h4>Tipo de Tabelas de Preço</h4>
                            <div class="form-row form-group" role="group">
                              <div class="col-sm-12 col-md-3">
                                <div class="form-group">
                                  <label for="tipo_sigla_id" class="col-form-label">Nome</label>
                                  <div class="col">
                                    <input
                                      v-model="tabelas.ttp_nome"
                                      class="form-control text-leftt"
                                      type="text"
                                      placeholder="digite a Sigla"
                                      id="sigla"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-card-body>
                        </b-card>
                      </form>
                    </div> -->
    </div>
</template>

<style scoped>
#borda {
    border: solid 2px black;
    border-radius: 12px;

}
</style>