<script>
export default {
  props: {
    // pelo nome  e id do objeto fazer uma pesquisa e armazenar a respota em uma vetor 
    // para colocar no foreach da tabela;
    produto_confec: { type: Object },
  },
  data() {
    return {

      items1:[
        {
           id: 1,
           nome:'ola'
        },
        {
          id: 2,
           nome:'ol2'
        },
         
        {
          id: 3,
          nome:'ol3'
        },
      ]

    }
  },
}
</script>

<template>
  <!-- <div class="Produto_confec">
  <p>ID: {{ produto_confec.id }}</p>
  <p>Nome: {{ produto_confec.produto_nome }}</p>
  <p>Usuário: {{ produto_confec.estado }}</p>
  <p>Ativo: <b-badge data-name="status" class="field-status" v-bind:variant="(produto_confec.status) ? 'success' : 'danger'">{{ (produto_confec.status) ? 'Sim' : 'Não' }}</b-badge></p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ produto_confec.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ produto_confec.updated_at }}</b-badge></p>
</div> -->

  <div>
    <b-table-simple>
      <b-thead id="b-head">
        <b-tr>
          <b-th class="b-th">Cod.Prod</b-th>
          <b-th class="b-th">Referencia</b-th>
          <b-th class="b-th">Classificação</b-th>
          <b-th class="b-th">Quantidade</b-th>
          <b-th class="b-th">unidade</b-th>
          <b-th class="b-th">Custo R$</b-th>
          <b-th class="b-th">Cosumo</b-th>
          <b-th>&nbsp;</b-th>
          <b-th>&nbsp;</b-th>
          <b-th>&nbsp;</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in items1" :key="index">
          <td>
            <input id="inputId" class="form-control text-let" v-model="item.id" value="index+1" type="hidden" disabled />
            <input id="id_seq" class="form-control text-let" v-bind:value="index + 1" disabled />
          </td>
          <b-td>
            <input type="password" class="form-control text-let" name="" id="" >
          </b-td>
          <b-td>
            <input type="password" class="form-control text-let" name="" id="" >
          </b-td>
          <b-td>
            <input type="password" class="form-control text-let" name="" id="" >
          </b-td>
          <b-td>
            <input type="password" class="form-control text-let" name="" id="" >
          </b-td>
          <b-td>
            <input type="password" class="form-control text-let" name="" id="" >
          </b-td>
          <b-td>
            <input type="password" class="form-control text-let" name="" id="" >
          </b-td>
        </b-tr>
      </b-tbody>

    </b-table-simple>

  </div>
</template>